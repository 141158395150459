import { useState, useEffect } from 'react';
import { LockOpenIcon } from '@heroicons/react/solid'
import axios from 'axios';

// Axios
axios.defaults.withCredentials = true

export default function Login(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [apiUrl, setApiUrl] = useState(null);
  const [signInButtonText, setSignInButtonText] = useState('Sign in');
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);

  function login() {
    axios.defaults.baseURL = apiUrl;
    setSignInButtonText('Signing in...');
    setLoginButtonDisabled(true);

    // Get user detail
    axios.post('/sanctum/token', {
      email: email,
      password: password,
      device_name: 'Grandizer App'
    })
      .then(function (response) {
        // handle success
        //console.log(response.data);
        if(response.data.status === 'error') {
          setErrorMessage(response.data.message);
          setSignInButtonText('Sign in');
          setLoginButtonDisabled(false);
        }
        if(response.data.status === 'success') {
          localStorage.setItem("api_base", apiUrl);
          localStorage.setItem("login_token", response.data.token);
          setSignInButtonText('Login success, please wait..');
          window.location.reload();
        }
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setErrorMessage('Something went wrong, please try again.')
        setLoginButtonDisabled(false);
      })
      .then(function () {
        //setIsLoaded(true);
      });
  }
 
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            {/* <img
              className="mx-auto h-12 w-auto"
              src="/images/grandizer_logo.png"
              alt="Your Company"
            /> */}
            <div className="h-12"></div>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Welcome
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Sign in to your account
            </p>
          </div>
          <div className="mt-8 space-y-6">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
            <div className="mb-2">
                <select
                    onChange={(e) => setApiUrl(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue=""
                >
                    <option value="">Select your Grandizer</option>
                    {process.env.NODE_ENV === 'development' && <option value="https://api.grandizer.test">Local</option>}
                    <option value="https://api.77m.grandizer.io">77 Media Holding</option>
                    <option value="https://api.7h.grandizer.io">7H Holdings Company</option>  
                </select>
            </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address *
                </label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email address *"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password *"
                />
              </div>
              {errorMessage && <div>
                <div className="text-red-600 text-sm font-medium py-3">{errorMessage}</div>
              </div>}
            </div>

            {/* <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </a>
              </div>
            </div> */}

            <div>
              <button
                onClick={login}
                type="submit"
                disabled={!email || !password || !apiUrl || loginButtonDisabled}
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-600 disabled:opacity-70"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockOpenIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                {signInButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
