import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CheckCircleIcon } from '@heroicons/react/solid';
import axios from 'axios';

// Routes
import Home from './features/home';
import Login from './features/login';

// Axios
axios.defaults.withCredentials = true

// Global variables
const grandizerV1Url = process.env.REACT_APP_V1_URL

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState([]);
  const [loginUrl, setLoginUrl] = useState(null);

  // Set page title
  useEffect(() => {
    document.title = `Grandizer Attendance`;
  }, [])

  useEffect(() => {
    const apiUrl = localStorage.getItem("api_base");
    const loginToken = localStorage.getItem("login_token");
    if(loginToken && apiUrl) {
      axios.defaults.baseURL = apiUrl
      axios.defaults.headers.common = {'Authorization': `Bearer ${loginToken}`}
      // Get user detail
      axios.get('/sanctum/csrf-cookie').then(response => {
        axios.get('/api/me')
          .then(function (response) {
            // handle success
            //console.log (response.data);
            if(response.data.status === 'not_logged_in') {
              setLoginUrl(grandizerV1Url+'/login?url='+window.location.href)
            } else {      
              setUser(response.data.data);
              setIsLoaded(true);
            }
            
          })
          .catch(function (error) {
            // handle error
            //setIsLoaded(true);
            setError(error);
          })
          .then(function () {
            //setIsLoaded(true);
          });
        });
    } else {
      setLoginUrl(grandizerV1Url+'/login?url='+window.location.href)
    } 
  }, [])

  // function getMobileOperatingSystem() {
  //   var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  //   // Windows Phone must come first because its UA also contains "Android"
  //   if (/windows phone/i.test(userAgent)) {
  //       return "Windows Phone";
  //   }

  //   if (/android/i.test(userAgent)) {
  //       return "Android";
  //   }

  //   // iOS detection from: http://stackoverflow.com/a/9039885/177710
  //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //       return "iOS";
  //   }

  //   return "unknown";
  // }

  if(!isLoaded) {
    if(loginUrl) {
      return <Login user={user} />
    } else {
      if(error) {
        return <div className="fullscreen-loading flex items-center justify-around p-10">We're sorry, something went wrong! Please pull down to refresh.</div>
      }
      return <div className="fullscreen-loading flex items-center justify-around">Loading..</div>
    }
  } else {
    if(!user.office && !user.is_remote) {
      return <div className="fullscreen-loading flex items-center p-10 text-sm">
        <div>
          <div className="mb-2 text-center">Welcome {user.name}!</div>
          <table>
            <tbody>
            <tr>
              <td><CheckCircleIcon className="block h-5 w-5 text-green-400 mr-2" aria-hidden="true" /></td>
              <td>Make sure you have installed Grandizer App if you have not installed it.</td> 
            </tr>
            <tr>
              <td><CheckCircleIcon className="block h-5 w-5 text-green-400 mr-2" aria-hidden="true" /></td>
              <td>Make sure you allow the app to use GPS settings when you do check in / out.</td> 
            </tr>
            <tr>
              <td><CheckCircleIcon className="block h-5 w-5 text-green-400 mr-2" aria-hidden="true" /></td>
              <td>Make sure admin (HR) setup your office informaiton.</td> 
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    } else {
      return (
        <BrowserRouter>
          <div className="min-h-full">
            {/* Main column */}
            <div className="flex flex-col">
              <main className="flex-1">
                <Routes>
                    <Route exact path="/" element={<Home user={user} />} />
                </Routes>
              </main>
            </div>
          </div>
        </BrowserRouter>
      );
    }
  }
}

export default App;
